import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts.ts\",\"import\":\"DM_Sans\",\"arguments\":[{\"variable\":\"--font-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts.ts\",\"import\":\"Manrope\",\"arguments\":[{\"variable\":\"--font-title\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Block"] */ "/vercel/path0/src/components/Block/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopNavbar"] */ "/vercel/path0/src/components/Navbar/DesktopNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavbar"] */ "/vercel/path0/src/components/Navbar/MobileNavbar.tsx");
