'use client';

import React, { FC, useEffect } from 'react';
import { Button } from '../ui/button';
import { LinkComponent } from '.';
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet';
import { useRouter, usePathname } from 'next/navigation';
import { cn } from '@/lib/utils';
import Link, { LinkProps } from 'next/link';
import { ScrollArea } from '../ui/scrollArea';
import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { TypographyH4 } from '../ui/h4';
import { TypographyP } from '../ui/p';
import Image from 'next/image';

const loadFeatures = () =>
	import('../../feature.js').then((res) => res.default);

export const MobileNavbar: FC<{ links: LinkComponent[] }> = ({ links }) => {
	const [open, setOpen] = React.useState(false);
	const [openSubMenu, setOpenSubMenu] = React.useState('');

	const path = usePathname();

	useEffect(() => {
		links.forEach((item) => {
			if (item.items?.some((nestedItem) => nestedItem.href === path)) {
				setOpenSubMenu(item.title);
				return;
			}
		});
	}, [path]);

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					variant='ghost'
					className='mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 lg:hidden'>
					<svg
						strokeWidth='1.5'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className='h-5 w-5'>
						<path
							d='M3 5H11'
							stroke='currentColor'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'></path>
						<path
							d='M3 12H16'
							stroke='currentColor'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'></path>
						<path
							d='M3 19H21'
							stroke='currentColor'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'></path>
					</svg>
					<span className='sr-only'>Toggle Menu</span>
				</Button>
			</SheetTrigger>
			<SheetContent side='right' className='pr-0 h-screen'>
				<Link href='/' className='left-4 relative -top-1'>
					<Image priority src='/logo.svg' width={36} height={36} alt='logo' />
				</Link>
				<ScrollArea className='relative min-h-[calc(65vh)] overflow-y-hidden mb-2 pb-10 pt-12 pr-10'>
					<div className='flex flex-col space-y-2 justify-start items-start'>
						{links.map((item, index) => (
							<div
								key={index}
								className='flex flex-col space-y-1 w-full overflow-hidden'>
								{!item?.items?.length ? (
									<MobileLink href={item.href ?? ''} onOpenChange={setOpen}>
										<h4
											className={cn(
												'font-medium text-xl w-full px-4 py-2 rounded-md',
												path === item.href && 'bg-secondary/30 '
											)}>
											{item.title}
										</h4>
									</MobileLink>
								) : (
									<button
										className='mr-auto text-xl px-4 py-2 rounded-md flex items-center w-full gap-x-6'
										onClick={() =>
											item.title === openSubMenu
												? setOpenSubMenu('')
												: setOpenSubMenu(item.title)
										}>
										<h4 className='font-medium'>{item.title}</h4>
										<ChevronDown
											size={20}
											className={cn(
												' duration-300',
												openSubMenu === item.title ? 'rotate-180' : ''
											)}
										/>
									</button>
								)}
								<LazyMotion features={loadFeatures}>
									<m.div initial={false} className='h-full'>
										<AnimatePresence initial={false}>
											{item?.items?.length && openSubMenu === item.title && (
												<m.div
													initial={{ height: 0 }}
													animate={{ height: 'auto' }}
													exit={{ height: 0 }}
													transition={{
														type: 'spring',
														duration: 0.4,
														bounce: 0,
													}}>
													{item.items.map((nestedItem, i) => (
														<m.div
															exit={{ opacity: 0, x: -20 }}
															initial={{ opacity: 0, x: -20 }}
															animate={{
																opacity: 1,
																x: 0,
																transition: {
																	duration: 0.3,
																	delay: 0.1 + i * 0.1,
																},
															}}
															className={cn(
																'px-4 py-2 rounded-md',
																path === nestedItem.href && 'bg-secondary/30 '
															)}
															key={nestedItem.href}>
															{nestedItem.href ? (
																<MobileLink
																	href={nestedItem.href}
																	onOpenChange={setOpen}
																	className='font-semibold text-muted-foreground text-base'>
																	{nestedItem.title}
																</MobileLink>
															) : (
																nestedItem.title
															)}
														</m.div>
													))}
												</m.div>
											)}
										</AnimatePresence>
									</m.div>
								</LazyMotion>
							</div>
						))}
					</div>
				</ScrollArea>
				<div className='pr-10 bg- mt-auto absolute bg-background bottom-8 flex flex-col'>
					<TypographyH4>Talk to a representative</TypographyH4>
					<TypographyP className='!mt-2'>
						From hospital operations to technical solutions. We&apos;re here to
						help.
					</TypographyP>
					<Button
						onClick={() => setOpen(false)}
						className='mt-4'
						el='link'
						href='/contact'
						size='lg'
						variant='default'>
						Contact us
					</Button>
				</div>
			</SheetContent>
		</Sheet>
	);
};

interface MobileLinkProps extends LinkProps {
	onOpenChange?: (open: boolean) => void;
	children: React.ReactNode;
	className?: string;
}

function MobileLink({
	href,
	onOpenChange,
	className,
	children,
	...props
}: MobileLinkProps) {
	const router = useRouter();
	return (
		<Link
			href={href}
			onClick={() => {
				router.push(href.toString());
				onOpenChange?.(false);
			}}
			className={cn(className)}
			{...props}>
			{children}
		</Link>
	);
}
